
var sliders = $(".slider-section"); 

$(sliders).each(function( index ) {
    $(this).attr('id', "slider" + index  );
});

$(sliders).each(function() {
    var sliderID = $(this).attr('id');
    $("#"+sliderID).slick({
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        adaptiveHeight: true
    });
});