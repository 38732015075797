import LazyLoad from "vanilla-lazyload";

require('./rwd-checker');
require('./form-animation');

$(function () {
    const lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy"
        // aby użyć do elementów dodajemy klasę .lazy
        // zamiast src w img podajemy data-src
        // zamiast background-image podajemy data-bg
    });

    require('./animations');
    require('./dropdown-menu');
    require('./mobile-menu');
    require('./cookie-bar');
    require('./sticky-header');
    require('./top-slider');
    require('./slider-sections');

    $('.gallery').each(function(){
        $(this).find('a').simpleLightbox();
    });
    if($('.simplelightbox').length > 0){
        $('.simplelightbox').simpleLightbox();
    }
});